import React from "react"
import classNames from 'classnames';
import { navigate } from '@reach/router';
// import PropTypes from "prop-types"

import Icon from '../Common/Icon/Icon';

import styles from "./Search.module.scss"

const TAGS = ['Vegan', 'Vegetarian', 'Restaurant', 'Cafe', 'Supermarket', 'Student Discount', 'BYO'];

const TagsFilter = ({ className }) => {
  const _cls = classNames(
    styles.tags,
    className
  );

  return (
    <div className={_cls}>
      {
        TAGS.map(t => (
          <label key={t}>
            <input type="checkbox" name="tag" value={t.toLowerCase()}/>
            <span>{t}</span>
          </label>
        ))
      }
    </div>
  );
}

const PriceFilter = ({ className, price, onPrice }) => {
  const priceCls = (num) => price && price >= num ? styles.active : ''
  return (
    <div className={`${styles.priceList} ${className}`}>
      <label className={priceCls(1)}>
        <input
          type="radio"
          onClick={onPrice}
          name="price"
          value="1"/>
        <span>£</span>
      </label>

      <label className={priceCls(2)}>
        <input
          type="radio"
          onClick={onPrice}
          name="price"
          value="2"/>
        <span>£</span>
      </label>

      <label className={priceCls(3)}>
        <input
          type="radio"
          onClick={onPrice}
          name="price"
          value="3"/>
        <span>£</span>
      </label>
    </div>
  );
}

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filters: {
        price: false
      }
    }

    this.dialog = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (this.props.show && !oldProps.show) {
      this.show();
    }
  }

  show = () => {
    requestAnimationFrame(() => {
      this.dialog.current.querySelector('input').focus();
    });
  }

  hide = () => {
    this.props.onClose();
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    });
  }

  setFilter = e => {
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]: e.target.value
      }
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const fd = new FormData(e.target);
    const search = new URLSearchParams(fd);

    navigate(`/?${search.toString()}`);

    this.hide();
  }

  shouldShowResult = ({ node }) => {
    const { search, filters } = this.state;
    const { tags, name, type, price } = node;

    const _search = search.toLowerCase();

    if (filters.price && price > filters.price) {
      return false;
    }

    if (search === '') {

      return true;
    }

    if (name.toLowerCase().includes(_search)) {
      return true;
    }

    if (type.toLowerCase().includes(_search)) {
      return true;
    }

    const tag = tags.find(t => t.toLowerCase().includes(_search));

    if (tag) {
      return true;
    }

    return false;
  }

  render() {
    const { className, show } = this.props;
    const { search, filters } = this.state;

    const cls = classNames(
      className,
      styles.dialog,
      {
        [styles.open]: show
      }
    );

    return (
      <div className={cls} ref={this.dialog}>
        <div className={styles.wrapper}>
          <form onSubmit={this.onSubmit} method="get" action="/" className={styles.search}>
            <input
              className={styles.input}
              type="search"
              name="search"
              value={search}
              onChange={this.onChange}
              placeholder="Search"/>

            <div className={styles.meta}>
              {/* <div className={styles.metaColumn}>
                <h2 className={styles.metaTitle}>Filter</h2>
              </div> */}

              <div className={styles.metaColumn}>
                <p className={styles.metaTitle}>Price</p>
                <PriceFilter price={filters.price} onPrice={this.setFilter} />
              </div>

              <div className={styles.metaColumnTwo}>
                <p className={styles.metaTitle}>Tags</p>
                <TagsFilter/>
              </div>
            </div>

            <button type="submit" className={styles.submit}>
              <span>Search</span>
            </button>

            <button type="button" onClick={this.hide} className={ styles.close }>
              <span className={styles.closeText}>Close</span> <Icon icon="close"/>
            </button>
          </form>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
}

export default Search
