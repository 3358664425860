import React from 'react';
import PropTypes from 'prop-types';

// 3rd Party Modules
import classNames from 'classnames';

// Redux

// Components

// CSS, Requires
import styles from "./Icon.module.scss";

const iconReq = require.context('!!svg-inline-loader!./icons', false, /\.svg/);

const Icon = ({ className, icon, size }) => {
  const getIcon = (key) => {
    const keys = iconReq.keys();
    const target = `./${key}.svg`;

    if (keys.indexOf(target) >= 0) {
      return iconReq(target);
    } else {
      return false;
    }
  }

  const cls = classNames(
    className,
    styles.icon,
    styles[size]
  );

  return (
    <span className={cls} dangerouslySetInnerHTML={{__html: getIcon(icon)}}/>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string
}

Icon.defaultProps = {
  size: 'medium'
};

export default Icon;
