import React from "react"
// import PropTypes from "prop-types";

import classNames from 'classnames';

import Icon from '../Common/Icon/Icon';

import styles from "./NavIcon.module.scss"

const NavIcon = ({ className, path, mapView, setMapView }) => {
  const toggleMap = () => {
    setMapView(!mapView);
  }

  const goBack = () => {
    window.history.back()
  };

  const cls = classNames(
      styles.btn,
      className,
      {
        [styles.root]: path === '/'
      }
  );

  // If its the home page, it must either be
  // the map icon or the list icon
  if (path === '/') {
    if (mapView) {
      return (
        <button
          className={cls}
          onClick={toggleMap}>
          <Icon
            size="free"
            className={styles.icon}
            icon="pr-list"/>
        </button>
      );
    } else {
      return (
        <button
          className={cls}
          onClick={toggleMap}>
          <Icon
            size="free"
            className={styles.icon}
            icon="pr-map"/>
        </button>
      );
    }
  }

  return (
    <button
      className={ cls }
      onClick={ goBack }>
      <Icon
        size="free"
        className={styles.icon}
        icon="pr-arrow-back"/>
    </button>
  );
};

NavIcon.propTypes = {
}

export default NavIcon;
