import React from "react"
import PropTypes from "prop-types"

// 3rd Party Modules
import { Location } from '@reach/router';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns';


// 1st Party Components
import Search from '../Search/Search';
import NavIcon from './NavIcon';
import SearchIcon from './SearchIcon';
import Icon from "../Common/Icon/Icon";
import Animation from '../Common/Animation/Animation';


// Redux
import { ACTIONS } from '../../state/createStore';


// Assets
import logoIcon from "../../images/header-logo.png";
// import splashLogo from "!!url-loader!../../images/svg/splash-logo.svg";
import splashJson from "../Common/Animation/splash.json";
import styles from "./Layout.module.scss";

// Variable to store PWA beforeinstallprompt
let deferredPrompt;

const PWA_DISMISS_KEY = 'pwa-dismiss';


class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      splashState: 1,
      message: false,
      pwaPrompt: false
    };

    this.deferredPrompt = false;
  }

  componentDidMount() {
    // // Triggers first stage of splash: Logo appear
    // setTimeout(() => {
    //   this.setSplashHide(1);
    // }, 500);

    // // Triggers second stage of splash: Splash disappear
    // setTimeout(() => {
    // }, 2000);

    // Registers the PWA prompt to capture install message
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.deferredPrompt = e;

      if (this.canShowPWA()) {
        this.showHomeScreenPrompt();
      }
    });
  }

  canShowPWA() {
    const dismissed = localStorage.getItem(PWA_DISMISS_KEY);

    if (!dismissed) {
      return true;
    }

    // Will allow prompt again after 5 days
    if (differenceInDays(new Date(), new Date(dismissed)) > 5) {
      return true;
    }

    return false;
  }


  /**
   * Sets splash state to a predefined stage
   *
   * @param {Number} num
   */
  setSplashHide = num => {
    this.setState({ splashState: num });
  }


  /**
   * Triggers displaying on banner for PWA
   * in manner which allows transition in of
   * the banner
   */
  showHomeScreenPrompt() {
    this.setState({
      message: true
    });


    // Double RAF to allow transition to work
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setState({
          pwaPrompt: true
        });
      });
    })
  }


  /**
   * Callback for user clicking add on PWA banner
   */
  addPwa = () => {
    this.deferredPrompt.prompt();

    this.hideBanner();

    deferredPrompt.userChoice
      .then(choiceResult => {
        deferredPrompt = null;
      });
  }


  /**
   * Catch all method of hiding the banner
   */
  hideBanner = () => {
    this.setState({
      pwaPrompt: false
    });

    setTimeout(() => {
      this.setState({
        message: false
      });
    }, 500);
  }

  dismissPwa = () => {
    localStorage.setItem(PWA_DISMISS_KEY, new Date().toISOString());

    this.hideBanner();
  }

  onSplashDone = () => {
    this.setSplashHide(2);
  }


  render() {
    const { children, mapView, setMapView, openSearch, searchOpen, closeSearch } = this.props;
    const { splashState, message, pwaPrompt } = this.state;

    const splashCls = classNames(
      styles.splash,
      {
        [styles.splashShow]: splashState === 1
      },
      {
        [styles.splashHide]: splashState === 2
      }
    );

    const messageCls = classNames(
      styles.message,
      {
        [styles.messagePwa]: pwaPrompt
      }
    );

    return (
      <Location>
        {
          locationProps => (
            <>
              <header className={styles.header}>
                <NavIcon
                  className={styles.icon}
                  mapView={ mapView }
                  setMapView={ setMapView }
                  path={locationProps.location.pathname}/>
                <Link
                  className={styles.title}
                  to="/">
                  <img alt="Where Walworth Eats Logo" src={logoIcon}/>
                </Link>
                <SearchIcon
                  onClick={openSearch}
                  className={styles.icon}/>
              </header>
              <Search
                show={ searchOpen }
                onClose={closeSearch}
                className={styles.search}/>
              <main className={styles.main}>{ children }</main>

              <div className={splashCls}>
                <Animation
                  onDone={this.onSplashDone}
                  json={splashJson}/>
                {/* <img alt="" src={splashLogo}/> */}
              </div>

              {
                message ? (
                  <div className={messageCls}>
                    <div className={`${styles.banner} ${pwaPrompt ? styles.bannerActive : null}`}>
                      <span>Add to your home screen?</span>
                      <button className={styles.advance} onClick={this.addPwa}>
                        Add <Icon icon="add-circle-outline"/>
                      </button>

                      <button className={styles.bannerClose} onClick={this.dismissPwa}>
                        <Icon size="small" icon="close"/>
                      </button>
                    </div>
                  </div>
                ) : null
              }
            </>
          )
        }
      </Location>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = ({ mapView, searchOpen }) => {
  return { mapView, searchOpen }
}

const mapDispatchToProps = dispatch => {
  return {
    setMapView: (mapView) => dispatch({ type: ACTIONS.SET_MAP_VIEW, value: mapView }),
    openSearch: () => dispatch({ type: ACTIONS.OPEN_SEARCH }),
    closeSearch: () => dispatch({ type: ACTIONS.CLOSE_SEARCH }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
