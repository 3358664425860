import React from "react"
// import PropTypes from "prop-types";

import classNames from 'classnames';

import Icon from '../Common/Icon/Icon';

import styles from "./SearchIcon.module.scss"

const NavIcon = ({ className, onClick }) => {
  const cls = classNames(
    styles.btn,
    className
  );

  return (
    <button className={cls} onClick={onClick}>
      <Icon size="large" className={styles.icon} icon="pr-search"/>
    </button>
  );
};

NavIcon.propTypes = {
}

export default NavIcon;
