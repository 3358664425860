import React, {useEffect, useState} from 'react';

import Lottie from 'react-lottie';

const Animation = ({json, onDone = () => {}}) => {
  const [dimensions, setDimensions] = useState({
    width: 250,
    height: 250
  });

  useEffect(() => {
    if (window.matchMedia('screen and (min-width: 950px)').matches) {
      setDimensions({
        width: 450,
        height: 450
      });
    }
  }, [])

  const options = {
    animationData: json,
    loop: false,
  };

  const eventListeners = [
    {
      eventName: 'complete',
      callback: () => {
        onDone()
      }
    }
  ];


  return (
    <Lottie
      options={options}
      width={dimensions.width}
      height={dimensions.height}
      eventListeners={eventListeners}/>
  );
};

export default Animation;
