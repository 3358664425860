import { createStore as reduxCreateStore } from "redux"

export const ACTIONS = {
  SET_MAP_VIEW: 'SET_MAP_VIEW',
  OPEN_SEARCH: 'OPEN_SEARCH',
  CLOSE_SEARCH: 'CLOSE_SEARCH'
}

const initialState = {
  mapView: false,
  searchOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_MAP_VIEW:
      return {
        ...state,
        mapView: action.value
      };
    case ACTIONS.OPEN_SEARCH:
      return {
        ...state,
        searchOpen: true
      }
    case ACTIONS.CLOSE_SEARCH:
      return {
        ...state,
        searchOpen: false
      }
    default:
      return state;
  }
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore;
